import * as React from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import AspectRatio from '@mui/joy/AspectRatio';
import FormControl, { FormControlProps } from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import { Template } from '../types';

export default function TemplateSelector(props: FormControlProps&{handleTemplateChange:Function,templates:Template[]}) {
  const { sx,templates,handleTemplateChange, ...other } = props;
  

  return (
    <FormControl
      {...other}
      sx={[{ display: { sm: 'contents' } }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <FormLabel>Template</FormLabel>
      <Autocomplete
        size="sm"
        autoHighlight
        isOptionEqualToValue={(option, value) => option === value}
        defaultValue={templates[0].name}
        options={templates.map(template=>template.name)}
        onChange={(e,v)=>handleTemplateChange(v)}
        renderOption={(optionProps, option) => (
          <AutocompleteOption {...optionProps}>
            {option}
            <Typography component="span" textColor="text.tertiary" ml={0.5}>
              ({(templates.find(template=>template.name===option) as Template).name })
            </Typography>
          </AutocompleteOption>
        )}
        slotProps={{
          input: {
            autoComplete: 'new-password', // disable autocomplete and autofill
          },
        }}
      />
    </FormControl>
  );
}

