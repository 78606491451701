import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Sheet from '@mui/joy/Sheet';
import MenuIcon from '@mui/icons-material/Menu';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import { toggleSidebar } from '../utils';
import { Box, IconButton, Typography } from '@mui/joy';
import ColorSchemeToggle from './ColorSchemeToggle';

export default function Header() {
  return (
    <Sheet
      sx={{
        display: { xs: 'sticky', md: 'none' },
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: 'var(--Header-height)',
        zIndex: 9995,
        p: 2,
        gap: 1,
        borderBottom: '1px solid',
        borderColor: 'background.level1',
        boxShadow: 'sm',
        }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Header-height': '52px',
            [theme.breakpoints.up('md')]: {
              '--Header-height': '0px',
            },
          },
        })}
      />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} onClick={()=>toggleSidebar()}>
          <IconButton variant="soft" color="primary" size="sm">
            <BrightnessAutoRoundedIcon />
          </IconButton>
          <Typography level="title-lg">Adbot</Typography>
          <ColorSchemeToggle sx={{ ml: 'auto' }} />
        </Box>
    </Sheet>
  );
}
