import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import { Main } from './pages/Main';
import { AuthProvider } from './auth/useAuth';
import NavProvider from './NavProvider';
import { AlertProvider } from './AlertProvider';

export default function MainLayout() {
  React.useEffect(()=>{
    document.title= `Adbot | ${process.env.REACT_APP_CLIENT_NAME || "doop"}`
  },[])

  return (
    <BrowserRouter>
      <AuthProvider>
        <NavProvider>
        <AlertProvider>
          <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Main />}></Route>
            </Routes>
          </CssVarsProvider>
        </AlertProvider>
        </NavProvider>
      </AuthProvider>

    </BrowserRouter>

  );
}
