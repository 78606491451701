import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import { useEffect, useState } from 'react';
import { axiosInstance } from '../auth/axios';
import { Template } from '../types';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

export default function Sidebar() {
  const { logout, user } = useAuth()
  const navigate = useNavigate()
  const [templates, setTemplates] = useState<Template[]>()

  useEffect(() => {
    if (!user) {
      return navigate("/login")
    }
    axiosInstance.get("api/templates")
      .then((res) => 
        res && setTemplates(res.data.templates)
      )
  }
    , [])

  return (
    <>
      <Box
        sx={{
          height: '100dvh',
          width: { md: 'var(--Sidebar-width)', xs: "0" },
        }}
      />
      <Sheet
        className="Sidebar"
        sx={{
          position: "fixed",
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
            md: 'none',
          },
          transition: 'transform 0.4s, width 0.4s',
          zIndex: 10000,
          height: '100dvh',
          width: 'var(--Sidebar-width)',
          top: 0,
          p: 2,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRight: '1px solid',
          borderColor: 'divider',
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ':root': {
              '--Sidebar-width': '220px',
              [theme.breakpoints.up('lg')]: {
                '--Sidebar-width': '240px',
              },
            },
          })}
        />
        <Box
          className="Sidebar-overlay"
          sx={{
            position: 'fixed',
            zIndex: 9998,
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            opacity: 'var(--SideNavigation-slideIn)',
            backgroundColor: 'var(--joy-palette-background-backdrop)',
            transition: 'opacity 0.4s',
            transform: {
              xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
              lg: 'translateX(-100%)',
            },
          }}
          onClick={() => closeSidebar()}
        />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <IconButton variant="soft" color="primary" size="sm">
            <BrightnessAutoRoundedIcon />
          </IconButton>
          <Typography level="title-lg">Adbot</Typography>
          <ColorSchemeToggle sx={{ ml: 'auto' }} />
        </Box>
        <Box
          sx={{
            minHeight: 0,
            overflow: 'hidden auto',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            [`& .${listItemButtonClasses.root}`]: {
              gap: 1.5,
            },
          }}
        >
          <List
            size="sm"
            sx={{
              gap: 1,
              '--List-nestedInsetStart': '30px',
              '--ListItem-radius': (theme) => theme.vars.radius.sm,
            }}
          >
            <ListItem component={Link} to="/" sx={{ textDecoration: 'none' }}>
              <ListItemButton>
                <HomeRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Home</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem component={Link} to="/materials/new" sx={{ mt: 0.5, textDecoration: 'none' }}>
              <ListItemButton color='primary'><AddCircleOutlineRoundedIcon/>Create new material</ListItemButton>
            </ListItem>
          </List>

        </Box>
        <Divider />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <AccountCircleRoundedIcon />
          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography level="title-sm">{user ? user.name : "username"}</Typography>
          </Box>
          <IconButton size="sm" variant="plain" color="neutral" onClick={() => {
            logout()
            navigate("/login")
          }}>
            <LogoutRoundedIcon />
          </IconButton>
        </Box>
      </Sheet>
    </>
  );
}
