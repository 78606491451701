import React, { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom';
import globalRouter from './globalRouter';


const NavProvider = (props: PropsWithChildren) => {
    const navigate = useNavigate();
    globalRouter.navigate = navigate;
  return (
    <>{props.children}</>
  )
}

export default NavProvider