import { Breadcrumbs, Link, Typography } from '@mui/joy'
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded"
import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import React from 'react'
import { useLocation } from 'react-router-dom'

type Props = {}

export const LocationAwareBreadcrums = (props: Props) => {

    const path = useLocation().pathname
    return (
        <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon fontSize="small" />}
            sx={{ pl: 0 }}
        >
            <Link
                underline="none"
                color="neutral"
                href="/"
                aria-label="Home"
                key={-1}
            >
                <HomeRoundedIcon />
            </Link>

            {
                path.split("/").slice(1).map(
                    (word,index)=>{
                        console.log(word)
                        return(
                        <Link
                        underline="hover"
                        color={index===path.split("/").length-2? "primary" : "neutral"}
                        href={path.split("/").slice(0,2+index).join("/")}
                        fontSize={12}
                        fontWeight={500}
                        key={index}
                    >
                        {word}
                    </Link>
                    )})
                
            }

        </Breadcrumbs>
    )
}