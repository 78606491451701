import React, { useEffect, useState } from 'react'
import { Autocomplete, AutocompleteOption, Box, Button, Card, CardOverflow, FormControl, FormLabel, List, ListItem, ListItemButton, Option, Select, Stack, Typography } from '@mui/joy'
import ListSubheader from '@mui/joy/ListSubheader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AssetBundleCard } from './AssetBundleCard';

type AssetBundle = any


type Props = {
  assetBundles: AssetBundle[]
  handleSelect: (arg0: AssetBundle) => void
  orientation: "h" | "v"
}

export const AssetBundleSelector = ({ orientation,assetBundles, handleSelect }: Props) => {

  const [selected, setSelected] = useState<AssetBundle >()

  useEffect(()=>setSelected(assetBundles.find(({name})=>name==="default")),[])
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Card
      sx={{
        padding: "0px",
        pb: open ?"10px":"",
        gap:"0px",
        m:"0px",
      }}
    >

          <Button onClick={() => setOpen(v => !v)} 
            color='neutral'
            variant='soft'
            sx={{maxHeight:"25px"}}
          >
            {open && "Hide" || "Select assetbundle"}
            <KeyboardArrowDownIcon
              sx={{ transform: open ?  'rotate(180deg)' : '' 
              }}
            />

          </Button>
          <Stack 
          direction="row"

          sx={{
            gap: open ?  "10px" : "",
            padding: open ? "10px" :"",
            px: "10px",
            opacity: open ? "1" : "0",
            transition: open? "opacity .5s, max-height .7s, padding .7s" :"opacity .25s, max-height .5s, padding .6s",
            overflow:"hidden",
            maxHeight: open ? "210px" : "0px"
          }}>

            {
                assetBundles.map(bundle =>
                  <AssetBundleCard orientation={orientation} bundle={bundle} onSelect={(bundle) => {setSelected(bundle);handleSelect(bundle)}} selected={ selected && selected.id === bundle.id}/>
                )
            }
          
      </Stack>
    </Card>
  )
}