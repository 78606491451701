import { Button, Card, CardContent, CardCover, CardOverflow } from '@mui/joy'
import React, { useEffect, useMemo, useState } from 'react'
import config from "../config.json"
import { axiosInstance } from '../auth/axios'
import { json } from 'stream/consumers'
import { CardMedia, darken } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type Props = {
    bundle: any
    onSelect: (arg0: any) => void
    selected: boolean
    orientation?: "h" | "v"
}

export const AssetBundleCard = ({ orientation = "v", selected, bundle, onSelect }: Props) => {

    return (
        <Card onClick={() => onSelect(bundle)} color={selected ? 'primary' : 'neutral'} sx={{
            padding: 0,
            height: orientation === "v" ? "198px" : "126px",
            width: orientation === "v" ? "108px" : "224px",
            overflow: "hidden",
            textAlign: "center",
            gap: 0,
            mt: "2px"
        }} >

            <CardCover>

                <img
                    src={config.BACKEND_URL + "/api/assetBundles/" + bundle.id + "/preview"}
                    style={{
                        position: "absolute",
                        height: "100%",
                    }}></img>


            </CardCover>
            {selected && <CardContent
                sx={{ alignItems: "flex-end", padding: "2px" }}>

                <CheckCircleIcon sx={{ backgroundColor: "white", borderRadius: "50%" }} />
            </CardContent>}
            <CardContent
                sx={{ justifyContent: 'flex-end', background: "linear-gradient(0deg, rgba(255,255,255,.8) 0%, rgba(255,255,255,0) 30%)" }}>
                {bundle.name}
            </CardContent>
        </Card>
    )
}