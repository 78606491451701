/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { ColorPaletteProp } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import TimelapseRoundedIcon from '@mui/icons-material/TimelapseRounded';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Material } from '../types';
import { useAuth } from '../auth/useAuth';
import { axiosInstance } from '../auth/axios';
import globalRouter from '../globalRouter';
import { useParams } from 'react-router-dom';
import config from "../config.json"
import { CircularProgress } from '@mui/joy';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | boolean | Date },
  b: { [key in Key]: number | string | boolean | Date },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function RowMenu({ row }: { row: Material }) {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem color="danger" onClick={async () => {
          // eslint-disable-next-line
          confirm("really wanna delete?") && await axiosInstance.delete("api/templates/" + row.templateId + "/materials/" + row.id).then(() =>
            globalRouter.navigate && globalRouter.navigate("/")
          )

        }}>Delete</MenuItem>
      </Menu>
    </Dropdown>
  );
}

type props = {
  pageLimit: number
  hideSearch?: boolean
  hidePagination?: boolean
}

export default function MaterialTable({ pageLimit, hidePagination, hideSearch }: props) {

  const [rows, setRows] = React.useState<Material[]>()
  const { user, logout } = useAuth()
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderByKey, setOrderByKey] = React.useState<keyof Omit<Material, "fields">>('created');
  const [search, setSearch] = React.useState<string>();
  const [filter, setFilter] = React.useState<string>("ALL")
  const [currentPage, setCurrentPage] = React.useState(1)

  const { page } = useParams()
  React.useEffect(() => {
    if (!user) {
      logout()
    }
    axiosInstance.post("api/materials/filter", {}).then(res => 
      res && setRows(res.data.materials)
    )
  }, [])

  const updateMaterials = () => {
    axiosInstance.post("api/materials/filter").then(res => 
      res && setRows(res.data.materials)
    )
  }
  React.useEffect(() => {

    const materialsAreRendering = rows?.some((row) => row.state === "PROCESSING")

    const interval = setInterval(updateMaterials, materialsAreRendering ? 1 * 10 ** 3 : 3 * 10 ** 4)

    return () => clearInterval(interval)

  }, [rows]);


  const renderFilters = () => (
    <Box >
      <FormControl size="sm">
        <FormLabel>State</FormLabel>
        <Select size="sm" placeholder="ALL" onChange={
          (e, value) => {
            if (value && typeof value === "string")
              setFilter(value)
          }}>
          <Option value="ALL">ALL</Option>
          {
            ["COMPLETED", "FAILED", "WAITING", "PROCESSING"].map(
              (state) =>
                <Option key={state} value={state}> {state} </Option>
            )
          }
        </Select>
      </FormControl>
    </Box>
  );
  if (!rows) return <Box display="flex" width="100%" height="100vh" ><CircularProgress sx={{ mx: "auto", my: "auto" }} /></Box>
  return (
    <Box sx={{ flex: 0, margin: "20px" }}>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: hideSearch ? "none" : "flex",
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search</FormLabel>
          <Input size="sm" placeholder="Search" onChange={e => setSearch(e.target.value)} startDecorator={<SearchIcon />} />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        variant="outlined"
        sx={{
          flex: 1,
          borderRadius: 'sm',
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
            "&":
              { 
              xs:{
                "& tr>*:nth-child(4)":{display:"none"}
              },               
              sm:{
                "& tr>*:nth-child(4)":{display:"none"}
              },
              md:{
                "& tr>*:nth-child(4)":{display:"table-cell"}
              }
            }
          }}
        >
          <thead>
            <tr>

              <th style={{ padding: '12px 6px' }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => { setOrder(order === 'asc' ? 'desc' : 'asc'); setOrderByKey("name") }}
                  fontWeight="lg"
                  endDecorator={<ArrowDropDownIcon />}
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  Name
                </Link>
              </th>
              <th style={{ padding: '12px 6px' }}>                <Link
                underline="none"
                color="primary"
                component="button"
                onClick={() => { setOrder(order === 'asc' ? 'desc' : 'asc'); setOrderByKey("created") }}
                fontWeight="lg"
                endDecorator={<ArrowDropDownIcon />}
                sx={{
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                  },
                }}
              >
                Created
              </Link></th>
              <th style={{ padding: '12px 6px' }}>                <Link
                underline="none"
                color="primary"
                component="button"
                onClick={() => { setOrder(order === 'asc' ? 'desc' : 'asc'); setOrderByKey("state") }}
                fontWeight="lg"
                endDecorator={<ArrowDropDownIcon />}
                sx={{
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                  },
                }}
              >
                State
              </Link></th>
                <th style={{  padding: '12px 6px', }}>ID</th>
              <th style={{ padding: '12px 6px' }}>
                {(hidePagination || hideSearch) && <Button variant='outlined' sx={{ height: "25px", minHeight: "0px" }} onClick={() => globalRouter.navigate && globalRouter.navigate("/materials")}>
                  Open full table
                </Button>}
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.filter(row => (filter === "ALL" || row.state === filter) && (!search || row.name.toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())))
              .sort(getComparator(order, orderByKey))
              .slice(pageLimit * (currentPage - 1), pageLimit * currentPage).map((row) => (

                <tr key={row.id} >
                  <td>
                    <Typography level="body-xs">{row.name}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{new Date(row.created).toISOString()}</Typography>
                  </td>
                  <td>
                    <Chip
                      variant="soft"
                      size="sm"
                      startDecorator={
                        {
                          WAITING: <HourglassEmptyRoundedIcon />,
                          PROCESSING: <TimelapseRoundedIcon />,
                          COMPLETED: <PlayCircleFilledRoundedIcon />,
                          FAILED: <ErrorRoundedIcon />,
                        }[row.state]
                      }
                      color={
                        {
                          COMPLETED: 'success',
                          WAITING: 'neutral',
                          FAILED: 'danger',
                          PROCESSING: "primary"
                        }[row.state] as ColorPaletteProp
                      }
                      sx={{
                        padding: "2px",
                        display: "inline-flex"
                      }}
                    >
                      {row.state}{row.state === "PROCESSING" && (" " + row.job_progress + "%")}
                    </Chip>
                  </td>
                    <td>

                      <Typography level="body-xs" sx={{opacity:"80%"}}>{row.id}</Typography>

                    </td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      {row.video ? <Link level="body-xs" href={config.BACKEND_URL + row.video} >
                        Download
                      </Link>
                        :
                        ""
                      }
                      <RowMenu row={row} />
                    </Box>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: hidePagination ? "none" : "flex"
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled= {currentPage <= 1}
          onClick={() => {
            setCurrentPage(n => n - 1)
            globalRouter.navigate && globalRouter.navigate("?page=" + (currentPage - 1))

          }
          }
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {([...Array(Math.ceil(rows.length / pageLimit))]
          .reduce((previous, _, index, array) => {
            if (index === 0 ||
              (index - 3 < currentPage && index + 3 > currentPage )||
              index === Math.floor(array.length) - 1) {
              if (index - previous[previous.length - 1] > 1)
                previous.push("...")
              return previous.concat(index)
            }
            return previous
          }
            , []) as (number | string)[])
          .map((page, index) => {
            if (typeof page === "number")
              page += 1

            return (
              <IconButton
                key={index}
                size="sm"
                color={page === currentPage ? "primary" : 'neutral'}
                variant={Number(page) ? 'outlined' : "plain"}
                disabled={!Number(page)}
                onClick={() => {
                  Number(page) && setCurrentPage(Number(page))
                  globalRouter.navigate && globalRouter.navigate("?page=" + page)
                }
                }

              >
                {page}
              </IconButton>
            )
          })}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          disabled = {currentPage-1>= Math.floor(rows.length / pageLimit) }
          onClick={() => {
            setCurrentPage(n => n + 1)
            globalRouter.navigate && globalRouter.navigate("?page=" + (currentPage + 1))
          }
          }
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
