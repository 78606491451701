import {ReactNode, createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { axiosInstance } from "./axios";

type Props = {
    children:ReactNode
}

const initialValue:authContext = {
    user:null,
    login:async (data:LoginInfo)=>{console.log("INITIAL")},
    logout:async ()=>{}
}

type User = {
    name:string,
    role:string,
    id:string,
    [x:string]:any
}

type LoginInfo = {
  name:string,
  password:string
}

type authContext = {
    user: User | null,
    login: (user:LoginInfo)=>Promise<void>,
    logout:()=>Promise<void>
}

const AuthContext = createContext(initialValue);

export const AuthProvider = ({ children }:Props) => {
  const [user, setUser] = useLocalStorage("user", null);

  const login = async (loginInfo:LoginInfo) => {

    const res = await axiosInstance.post("/auth/login",loginInfo)
    res && setUser(res.data);
  };

  const logout = async () => {
    setUser(null);
    await axiosInstance.post("/auth/logout")
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};