import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import { useEffect, useState } from 'react';
import VideoFileRoundedIcon from '@mui/icons-material/VideoFileRounded';
import { Checkbox, FormHelperText, IconButton, Slider, Textarea } from '@mui/joy';
import TemplateSelector from './TemplateSelector';
import FileUploader from './FileUploader';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { useAuth } from '../auth/useAuth';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../auth/axios';
import { AllowedAngle, MaterialField, MaterialInfo, Template, TemplateConfig } from '../types';
import { alerts } from '../AlertProvider';
import MaterialTable from './MaterialTable';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { PreviewImage } from './PreviewImage';
import validator from 'validator';
import { AssetBundleSelector } from './AssetBundleSelector';
import axios from 'axios';

type previewImage = {
    url?: string,
    loading?: boolean,
    fail?: boolean
}

export default function CreateMaterialForm() {
    const [info, setInfo] = useState<MaterialInfo>({})
    const [fields, setFields] = useState<MaterialField[]>([])
    const { logout, user } = useAuth()
    const navigate = useNavigate()
    const [templates, setTemplates] = useState<Template[]>()
    const [showRotation, setShowRotation] = useState(false)
    const ref = React.useRef(null)
    const [isDragging, setIsDragging] = useState(false)
    const [previewImage, setPreviewImage] = useState<previewImage>()
    const [previewFrameOffset, setPreviewFrameOffset] = useState<number>(0)
    const [TemplateConfig, setTemplateConfig] = useState<TemplateConfig>()
    const [assetBundles, setAssetBundles] = useState<any[]>()
    const showPreviewFrameButtons = false
    const [renderStarted, setRenderStarted] = useState<boolean>(false)
    const [orientation, setOrientation] = useState<"h" | "v">("v")
    const [previewController, setPreviewController] = useState<AbortController>()

    useEffect(() => {
        if (TemplateConfig) {
            setOrientation(TemplateConfig.height > TemplateConfig.width ? "v" : "h");
        }
    }, [TemplateConfig])

    const getValidFields = (newFields?: MaterialField[]) => {
        const valids = (newFields || fields).filter(
            field => !(field.invalid)
        )
        return valids
    }

    const inputIsValid = () => !renderStarted && info.name && !fields.some(field => field.invalid)

    useEffect(() => {
        if (!assetBundles)
            return
        const defaultAssetBundle = assetBundles.find(({ name }) => name === "default")
        defaultAssetBundle && handleAssetBundleChange(defaultAssetBundle)
    }, [assetBundles])

    useEffect(() => {
        if (!user) {
            logout()
            return navigate("/login")
        }
        axiosInstance.get("api/templates?include=" + JSON.stringify({ fields: true, TemplateConfig: true, assetBundles: { include: { fields: true } } }))
            .then((res) => {
                if (res) {
                    setTemplates(res.data.templates)
                    const { name } = res.data.templates[0]
                    setInfo({ ...info, name })
                    setAssetBundles(res.data.templates[0].assetBundles)
                    handleSelectTemplate(name, res.data.templates)
                }
            })
        window.addEventListener('mouseup', setIsDraggingToFalse);
        window.addEventListener('dragenter', setIsDraggingToTrue);
        window.addEventListener('mouseout', setIsDraggingToFalse)
        window.addEventListener('mouseleave', setIsDraggingToFalse)
    }
        , [])


    const setIsDraggingToFalse = (event: MouseEvent) => {
        setIsDragging(false)
    }

    const setIsDraggingToTrue = (event: MouseEvent) => {
        setIsDragging(true)
    }


    // Update preview image based on given fields or current state
    const updatePreviewImage = (newFields?: MaterialField[]) => {
        const body: any = {
            fields: getValidFields(newFields).filter(field => field.value).filter(field => field.value !== field.defaultValue).map(({ value, key, type }) => ({ value, key, type })),
            ...info,
            name: info.name || "default"
        }
        if (!TemplateConfig)
            return
        if (previewFrameOffset) {
            body.frame = Math.max(Math.min(TemplateConfig.defaultPreviewFrame + previewFrameOffset,
                TemplateConfig.durationInFrames
            ), 0)
        }

        setPreviewImage(obj => ({ ...obj, loading: true }))

        const newPreviewController = new AbortController()
        setPreviewController(newPreviewController)
        return axiosInstance.post(`/api/templates/${info.templateId}/preview`, body, { signal: newPreviewController.signal, responseType: 'blob' })
            .then(async (response) => {
                const url = URL.createObjectURL(response.data)
                setPreviewImage({ url, loading: false })
            })
            .catch((thrown) => axios.isCancel(thrown) || setPreviewImage(obj => ({ ...obj, loading: false, fail: true })))
    }

    useEffect(() => {

        if (!info.templateId)
            return

        const timeOut = setTimeout(() => {
            updatePreviewImage()
        }, 3000)
        return () => clearTimeout(timeOut)
    }, [info, fields, previewFrameOffset])


    const handleInfoChange = (values: Partial<MaterialInfo>) => {
        setInfo(old => ({ ...old, ...values }))
    }

    const renderMaterial = () => {

        if (!inputIsValid() || renderStarted)
            return

        const body = {
            fields: fields.filter(field => field.value),
            ...info
        }
        setRenderStarted(true)
        axiosInstance.post(`/api/templates/${info.templateId}/materials`, body).then(
            () => alerts.show && alerts.show("primary", "Render has started", 3000)
        ).catch(
            (e) => {
                alerts.show && alerts.show("danger", "Could not start render :(", 3000)
            }
        )
    }

    const handleAssetBundleChange = async (bundle: { fields: { type: string, key: string, value: string, id: string }[] }) => {

        const newFields: MaterialField[] = fields.map(
            (field) => {
                const bundleField = bundle.fields.find(({ key }) => key === field.key)
                if (!bundleField)
                    return field
                return {
                    ...field,
                    ...(bundleField.type === "IMAGE"
                        ? { value: bundleField.id, invalid: false, name: bundleField.value }
                        : { value: bundleField.value, invalid: false, name: undefined })
                }
            }
        )
        setFields(newFields)
        return updatePreviewImage(newFields)
    }

    const handleSelectTemplate = (value: string, templates_manual?: Template[]) => {

        if (previewController) {
            previewController.abort()
        }

        const newTemplate = (templates_manual || templates)?.find((template) => template.name === value)
        if (!newTemplate)
            return
        const { id } = newTemplate
        const fields = newTemplate.fields
        fields.sort((a, b) => a.displayIndex - b.displayIndex)
        setFields(fields)
        setInfo({ ...info, templateId: id })
        setTemplateConfig(newTemplate.TemplateConfig)
        setRenderStarted(false)
        setAssetBundles(newTemplate.assetBundles)
    }

    const handleFieldValueChange = (key: string, value: string) => {
        const index = fields.findIndex((field) => field.key === key)
        const newFields = [...fields]
        setRenderStarted(false)
        newFields[index].value = value
        setFields(
            newFields
        )
    }

    const handleFileChange = (key: string, file: File | File[] | string) => {
        if (Array.isArray(file))
            return alert("ONLY ONE FILE PLEASE")
        if (fields.some((field) => field.type === "IMAGE" && field.key === key)) {
            if (typeof file !== "string") {
                const formData = new FormData()
                formData.append("file", file)
                axiosInstance.post("/api/upload_file", formData)
                    .then(res => {
                        setRenderStarted(false)
                        setFields(
                            (fields) => fields.map(
                                field => field.key === key ? { ...field, value: res.data.id, name: file.name, invalid: false } : field
                            )
                        )
                    })
                    .catch(e => setFields(
                        (fields) => fields.map(
                            field => field.key === key ? { ...field, name: file.name, value: undefined, invalid: true } : field
                        )
                    ))

            }
            else {
                setFields(
                    fields => fields.map(
                        field => field.key === key ? { ...field, value: file, invalid: !validator.isURL(file), name: undefined } : field
                    )
                )
            }
        }

    }


    return (
        <Box sx={{ flex: 1, width: '100%' }} ref={ref}>
            <Box
                sx={{
                    position: 'sticky',
                    top: { sm: -100, md: -110 },
                    bgcolor: 'background.body',
                    zIndex: 9995,
                }}
            >
                <Box sx={{ px: { xs: 2, md: 6 } }}>
                    <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
                        Create new material
                    </Typography>
                </Box>

            </Box>
            <Stack direction='row'
                sx={{}}>
                <Card sx={{ width: "100%", ml: "20px", my: 5, display: "flex" }}>
                    <Stack justifyContent="space-between" sx={{ mb: 1 }} direction={'row'}>
                        <Typography level="title-md">Material Info</Typography>
                        <Stack direction="row" spacing={1}>
                            <div>
                                <Typography color='neutral' sx={{ opacity: "80%", marginTop: "-4px" }}>
                                    show rotation controls
                                </Typography>
                            </div>
                            <Checkbox onChange={(e) => setShowRotation(e.target.checked)} />
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{ display: { xs: 'flex', md: 'flex' }, my: 1 }}
                    >
                        <Stack spacing={2} sx={{ flexGrow: 1, }} >

                            <Stack spacing={1}>
                                <FormLabel>Name</FormLabel>
                                <FormControl
                                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                                >
                                    <Input size="sm" placeholder="New Material" sx={{ flexGrow: 1 }} onChange={(e) => handleInfoChange({ name: e.target.value })} />
                                </FormControl>
                            </Stack>
                            <Stack direction="row" spacing={2}
                                sx={{
                                    maxHeight: showRotation ? "70px" : "0px",
                                    transition: showRotation ? "max-height .7s, opacity 1s" : "max-height .3s, opacity .2s",
                                    opacity: showRotation ? "1" : "0"
                                }}>
                                <VideoFileRoundedIcon
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        transition: "rotate .5s, transform .5s",
                                        rotate: (info.rotation ? info.rotation : 0).toString() + "deg",
                                        transform: info.flip ? "rotateX(180deg)" : ""
                                    }} />

                                <FormControl sx={{ flexGrow: 1 }}>
                                    <FormLabel sx={{ flexGrow: 1 }}>Rotation</FormLabel>
                                    <Stack direction="row" sx={{ flexGrow: 1 }}>
                                        <Slider
                                            value={info.rotation}
                                            step={90}
                                            max={270}
                                            onChange={(e, v) => !Array.isArray(v) && [0, 90, 180, 270].includes(v) && handleInfoChange({ rotation: v as AllowedAngle })}
                                            marks={[0, 90, 180, 270].map((n) => ({ label: n.toString() + "°", value: n }))}
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl sx={{ flexGrow: 1 }}>
                                    <FormLabel>Flip</FormLabel>
                                    <Checkbox onChange={e => handleInfoChange({ flip: e.target.checked })} />
                                </FormControl>
                            </Stack>
                            <div>
                                {templates && <TemplateSelector handleTemplateChange={handleSelectTemplate} templates={templates} />}
                            </div>
                            {assetBundles && assetBundles.length > 1 && <AssetBundleSelector orientation={orientation} assetBundles={assetBundles} handleSelect={handleAssetBundleChange} />}

                            <Divider />
                            <Typography level='title-md'>
                                Fields
                            </Typography>
                            {
                                fields.map(field =>
                                    <FormControl error={field.invalid} key={info.templateId + field.key}>

                                        <FormLabel >{field.humanReadableKey || field.key}</FormLabel>

                                        {field.type === "IMAGE" ?
                                            <FileUploader handleChange={f => handleFileChange(field.key, f)} >
                                                <Input value={field.name || field.value}
                                                    onBlur={() => updatePreviewImage()}
                                                    placeholder="Enter a url or drag a file here"
                                                    sx={
                                                        isDragging ?
                                                            {
                                                                boxShadow: "0px 0px 10px var(--joy-palette-success-solidBg)",
                                                                transition: "box-shadow 1s"
                                                            }
                                                            : {
                                                                transition: "box-shadow .5s"
                                                            }
                                                    }
                                                    onFocus={(e) => e.target.select()}
                                                    onChange={(e) => handleFileChange(field.key, e.target.value)} endDecorator={<FileUploadRoundedIcon color={field.name ? "success" : "info"} />} />
                                            </FileUploader>
                                            : (field.type === "TEXTAREA" ?
                                                <Textarea minRows={2} value={field.value} onBlur={() => updatePreviewImage()} onChange={e => handleFieldValueChange(field.key, e.target.value)} />
                                                :
                                                <Input value={field.value} onBlur={() => updatePreviewImage()} onChange={e => handleFieldValueChange(field.key, e.target.value)} />

                                            )

                                        }
                                        {field.invalid && <FormHelperText>
                                            <InfoOutlined />
                                            {field.name ? "Could not upload file" : "Invalid URL"}
                                        </FormHelperText>}

                                    </FormControl>
                                )
                            }
                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                <Button size="sm" variant="outlined" color="neutral" href='/' component={Link}>
                                    Cancel
                                </Button>
                                <Button disabled={!inputIsValid()} size="sm" variant="solid" onClick={renderMaterial}>
                                    Render
                                </Button>
                            </CardActions>

                        </Stack>
                    </Stack>

                </Card>
                <Stack
                    sx={{
                        maxWidth: "50%",
                        minWidth: "20px"
                    }}
                >
                    {previewImage && <Card
                        sx={{
                            my: 5,
                            padding: 0,
                            mx: "10px",
                            overflow: "hidden",
                            position: "sticky",
                            top: 15,
                        }}
                    >
                        <CardOverflow
                        >

                            <PreviewImage imgStyle={{
                                maxHeight: "calc(100vh - 30px)",
                                objectFit: "contain"
                            }}
                                src={previewImage.url}
                                loading={previewImage.loading}
                                fail={previewImage.fail}
                            />
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    width: "100%",
                                }}
                            >
                                {
                                    showPreviewFrameButtons && <>
                                        <IconButton
                                            variant="soft"

                                            sx={{
                                                m: 1,
                                                "&:not(:hover)": {
                                                    opacity: .5
                                                }
                                            }}
                                            onClick={() =>
                                                setPreviewFrameOffset((n) => n - 10)
                                            }
                                        >
                                            <ArrowBackIosNewIcon />
                                        </IconButton>
                                        <Box sx={{
                                            position: 'absolute',
                                            left: "50%",
                                            top: 10,
                                        }}>
                                            <Typography
                                                sx={{
                                                    color: "white",
                                                    ml: "-50%"
                                                }}
                                            >
                                                Frame {TemplateConfig && TemplateConfig.defaultPreviewFrame + previewFrameOffset}
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            variant="soft"
                                            sx={{
                                                right: 0,
                                                position: 'absolute',
                                                m: 1,
                                                "&:not(:hover)": {
                                                    opacity: .5
                                                }
                                            }}
                                            onClick={() =>
                                                setPreviewFrameOffset((n) => n + 10)
                                            }
                                        >
                                            <ArrowForwardIosIcon />
                                        </IconButton>
                                    </>}

                            </Box>
                        </CardOverflow>
                    </Card>}
                </Stack>
            </Stack>
            <MaterialTable hideSearch pageLimit={6} key={+renderStarted} />
        </Box>
    );
}